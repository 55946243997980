import React from "react"
import { useState } from "react"
const Info = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className='about__info grid'>
      <div className='about__box'>
        {open ? (
          <div className='about__title'>
            <i class='bx bx-briefcase-alt'></i>
            <p className=''>Hover to check Experience</p>
          </div>
        ) : (
          <>
            <i class='bx bx-briefcase-alt'></i>
            <h3 className='about__title'>Experience</h3>
            <span className='about__subtitle'>4+ Years</span>
          </>
        )}
      </div>
      <div
        className='about__box'
        // onMouseEnter={() => setOpen(true)}
        // onMouseLeave={() => setOpen(false)}
      >
        {open ? (
          <i class='bx bx-award'></i>
        ) : (
          <>
            <i class='bx bx-certification'></i>
            <h3 className='about__title'>Education</h3>
            <span className='about__subtitle'>Software Engineering</span>
          </>
        )}
      </div>
      <div
        className='about__box'
        // onMouseEnter={() => setOpen(true)}
        // onMouseLeave={() => setOpen(false)}
      >
        {open ? (
          <i class='bx bx-award'></i>
        ) : (
          <>
            <i class='bx bx-award'></i>
            <h3 className='about__title'>Contests</h3>
            <span className='about__subtitle'>3+ Won</span>
          </>
        )}
      </div>
    </div>
  )
}

export default Info
