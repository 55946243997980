import React from "react"
import "./work.css"

const Work = () => {
  return (
    <section className='work section' id='work'>
      <h2 className='section__title'>Qualifications</h2>
      <span className='section__subtitle'>My Work Journey</span>
      <div className='work__container container'>
        <div className='work__tabs'>
          <div className='work__button button--flex'>
            <i className='uil uil-briefcase-alt work__icon'></i>
            Work
          </div>
        </div>
        <div className='work__sections'>
          <div className='work__content work_content--active'>
            <div className='work__data'>
              <div>
                <h3 className='work__title'>Software Development Engineer</h3>
                <span className='work__subtitle'>Amazon Inc.</span>
                <div className='work__calender'>
                  <i className='uil uil-calendar-alt'></i>
                  &nbsp;Sept'2022 - Present
                </div>
              </div>

              <div>
                <span className='work__rounder'></span>
                <span className='work__line'></span>
              </div>
            </div>
            <div className='work__data'>
              <div></div>

              <div>
                <span className='work__rounder'></span>
                <span className='work__line'></span>
              </div>

              <div>
                <h3 className='work__title'>
                  Associate Developer / Consultant
                </h3>
                <span className='work__subtitle'>
                  IBM (Global Buisness Services)
                </span>
                <div className='work__calender'>
                  <i className='uil uil-calendar-alt'></i>
                  &nbsp;May'2022 - Sept'2022
                </div>
              </div>
            </div>
          </div>

          <div className='work__content'>
            <div className='work__data'>
              <div>
                <h3 className='work__title'>Junior Full Stack Developer</h3>
                <span className='work__subtitle'>Veer.ai Inc.</span>
                <div className='work__calender'>
                  <i className='uil uil-calendar-alt'></i>
                  &nbsp;Aug'2021 - Oct'2021
                </div>
              </div>

              <div>
                <span className='work__rounder'></span>
                <span className='work__line'></span>
              </div>
            </div>

            <div className='work__data'>
              <div></div>

              <div>
                <span className='work__rounder'></span>
                <span className='work__line'></span>
              </div>

              <div>
                <h3 className='work__title'>Full Stack Developer</h3>
                <span className='work__subtitle'>IBM</span>
                <div className='work__calender'>
                  <i className='uil uil-calendar-alt'></i>
                  &nbsp;Sept'2019 - Dec'2020
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Work
