import React from "react"
import "./header.css"

const Header = () => {
  return (
    <header className='header'>
      <nav className='nav container'>
        <a href='index.html' className='nav__logo'>
          CHIRAG SARDANA
        </a>
        <div className='nav__menu'>
          <ul className='nav__list grid'>
            <li className='nav__item'>
              <a href='#home' className='nav__link'>
                <i className='uil uil-estate nav__icon'></i> Home
              </a>
            </li>
            <li className='nav__item'>
              <a href='#about' className='nav__link'>
                <i className='uil uil-trophy nav__icon'></i> About Me
              </a>
            </li>
            <li className='nav__item'>
              <a href='#work' className='nav__link'>
                <i className='uil uil-briefcase-alt nav__icon'></i> Work
              </a>
            </li>
            {/* <li className='nav__item'>
              <a href='#creative' className='nav__link'>
                <i className='uil uil-coffee nav__icon'></i> Creative
              </a>
            </li> */}
            <li className='nav__item'>
              <a href='#contact' className='nav__link'>
                <i className='uil uil-message nav__icon'></i> Contact Me
              </a>
            </li>
          </ul>
          <i className='uil uil-times nav__close'></i>
        </div>
      </nav>
    </header>
  )
}

export default Header
