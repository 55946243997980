import React, { useEffect, useState } from "react"
import "./App.css"
import About from "./components/about/about"
import Header from "./components/header/header"
import Home from "./components/home/home"
import Loader from "./components/loader/loader"
import Work from "./components/work/work"
import Contact from "./components/contact/contact"

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3050)
  }, [])
  return (
    <div className='App'>
      {loading ? (
        <Loader loadingState={loading}></Loader>
      ) : (
        <>
          <Header></Header>
          <main className='main'>
            <Home></Home>
          </main>
          <About></About>
          <Work></Work>
          <Contact></Contact>
        </>
      )}
    </div>
  )
}
export default App
