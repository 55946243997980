import PropagateLoader from "react-spinners/PropagateLoader"

const Loader = (props) => {
  const override = {
    position: "absolute",
    justifyContent: "center",
    top: "50%",
    left: "50%"
  }
  return (
    <PropagateLoader
      loading={props.loadingState}
      color={"#000000"}
      speedMultiplier={1}
      size={15}
      cssOverride={override}
    />
  )
}

export default Loader
