import React from "react"

const Social = () => {
  return (
    <div className='home__social'>
      <a
        href='https://www.instagram.com/csardana_98/'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-instagram'></i>
      </a>
      <a
        href='https://www.linkedin.com/in/chirag-sardana-253285161/'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-linkedin'></i>
      </a>
      <a
        href='https://github.com/csardana'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-github'></i>
      </a>
    </div>
  )
}

export default Social
