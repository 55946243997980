// import "../header/header.css"
import "./contact.css"
import React from "react"

const Contact = () => {
  return (
    <section className='contact section' id='contact'>
      <div className='contact container'>
        <h2 className='section__title'>Contact Me</h2>
        <div className='contact__menu'>
          <div className='contact__list grid'>
            <div className='contact__item'>
              <a
                href='https://www.instagram.com/csardana_98/'
                className='contact__social-icon'
              >
                <i class='uil uil-instagram contact-icon'></i>
              </a>
            </div>
            <div className='contact__item'>
              <a
                href='https://www.linkedin.com/in/chirag-sardana-253285161/'
                className='contact__social-icon'
              >
                <i class='uil uil-linkedin contact-icon'></i>
              </a>
            </div>
            <div className='contact__item'>
              <a
                href='https://www.facebook.com/chirag.sardana.581/'
                className='contact__social-icon'
              >
                <i class='uil uil-facebook contact-icon'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Contact
