import React from "react"
import Data from "./data"
import Social from "./social"
import "./home.css"
import ScrollDown from "./scrollDown"

const Home = () => {
  return (
    <section className='home section' id='home'>
      <div className='home__container container grid'>
        <div className='home__content grid'>
          <Social></Social>
          <div className='home__img'></div>
          <Data></Data>
        </div>
        <ScrollDown></ScrollDown>
      </div>
    </section>
  )
}

export default Home
